import { useSelector } from "react-redux";
import { selectAuth } from "../../../ReduxToolkit/Slices/LoginSlice";

import HeaderSearch from "./components/HeaderSearch";
import HeaderLeft from "./components/HeaderLeft";
import LoggedUser from "./components/LoggedUser";
import NavBar from "../../Element/NavBar";
import ItemCart from "../../Element/ItemCart";

const NewHeader = ({ mainMenu }) => {
  const auth = useSelector(selectAuth)

  return (
    <header className="new__header">
      <HeaderLeft mainMenu={mainMenu} />
      <div className="right__wrapper">
        <HeaderSearch mainMenu={mainMenu} />
        <nav style={{
          marginLeft: '29px'
        }}> 
            <NavBar mainMenu={mainMenu} />
        </nav>
        <LoggedUser />
        {
          !auth && <div className="basket__wrapper">
            <ItemCart/>
          </div>
        }
      </div>
    </header>
  )
};

export default NewHeader;